.annotation-primary {
    background-color: rgba(255, 228, 173, 0.6); /* Light yellow */
    display: inline;
  }
  
  .annotation-secondary {
    background-color: rgba(193, 232, 240, 0.6); /* Light blue */
    display: inline;
  }
  
  .annotation-overlap {
    background-color: rgba(71, 255, 99, 0.6); /* Light green */
    display: inline;
  }
  

  