.css-19kzrtu {
    padding: 0px !important;
}

.all_feedback_container{
    padding-top: 24px;
    padding-bottom: 24px;
}   

.flex_container{
    display: flex;
    flex: 1;
    overflow: hidden;
}

.feedback_container{
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
    padding-left: 0;
}

.feedback_textarea{
    border: 1px solid #5B616B;
    width: 100%;
    resize: vertical;
    min-height: 40px;
    margin-top: 16px;
    overflow: hidden;
}

.myfeedback_container{
   flex: 1;
   overflow-y: auto;
 }

.myfeedback_header_font{
   font-weight: 600;
   font-size: 14px;
   color: #242424;
}

.myfeedback_form_padding{
    padding-top: 32px; 
}

.myfeedback_required_container{
    display: flex;
    align-items: center;
    gap: 4px;
}

.myfeedback_required_font{
    font-weight: 600;
    font-size: 14px;
    color:red;
}

.myfeedback_button_container{
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 16px;
    padding-bottom: 2px;
}

.allfeedback_table_headers{
    font-weight: 600;
    font-size: 14px;
    color: #242424;
    background-color: white !important;
    min-width: 115px;
    position: sticky;
    top: 0;
    z-index: 10;
}

.headers_no_flex{
    flex-grow: 1 !important;
    width: 100% !important;
}

.headers_flex{
    width: auto !important;

}
  
.allfeedback_table_cell{
    font-weight: 400;
    font-size: 14px;
    color: #242424;
}

.nowrap{
    white-space: nowrap;
}

.allfeedback_table_cell_skeleton{
    font-weight: 400;
    font-size: 14px;
    color: #242424;
}

.w-100{
    width: 100%;
}

.overflow-auto{
    overflow: auto;
}

.select-field {
    width: 325px;
    max-width: 325px;
  }
  
  .select-field .MuiOutlinedInput-root {
    width: 100%;
    max-width: 325px;
  }
  
  .select-field .MuiAutocomplete-inputRoot {
    max-width: 325px;
  }
  
  .select-field .MuiInputBase-root {
    max-width: 325px;
  }

  .collapse-transition{
    transition: opacity 0.5s ease-in-out;
  }
  
  .tabs-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D6D7D9;
  }
  
  .custom-tabs .MuiTabs-indicator {
    background-color: #0096F4;
    height: 6px;
  }
  
  .custom-tabs .MuiTab-root {
    color: #242424;
    font-weight: 400;
    text-transform: none;
  }
  
  .custom-tabs .MuiTab-root.Mui-selected {
    color: #242424;
    font-weight: 600;
  }