.toast {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100%;
    min-width: 500px;
    background-color: #ffffff !important;
    color: #242424 !important;
    box-shadow: 0px 4px 4px 0px #55555540 !important;
    border-radius: 6px !important;
    padding: 4px 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left-width: 4px;
}

.toast .MuiAlert-message {
    display: flex !important;
    flex-grow: 1 !important;
    align-items: center !important;
    gap: 8px !important;
}

.toast .MuiAlert-action {
    display: flex !important;
    align-items: center !important;
    gap: 12px !important; 
}

.toast[data-severity="success"] { border: 1px solid #2E8540 !important; border-left-width: 4px !important; }
.toast[data-severity="error"] { border: 1px solid #E31C3D !important; border-left-width: 4px !important; }
.toast[data-severity="warning"] { border: 1px solid #FFC737 !important; border-left-width: 4px !important; }
.toast[data-severity="info"] { border: 1px solid #205493 !important; border-left-width: 4px !important; }

.toast-divider {
    color: #D6D7D9;
}

.toast-icon {
    width: 20px;
    height: 20px;
  }
  
